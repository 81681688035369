.header {
    display: flex;
    padding: var(--padding-regular);
    background-color: var(--background-secondary);
    transition: var(--theme-transition);
    -moz-transition: var(--theme-transition);
    -webkit-transition: var(--theme-transition);
}
.header > * {
    min-height: var(--header-min-height);
    max-height: var(--header-max-height);
}
.scramble_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    overflow-y: scroll;
    padding: 0rem var(--padding-regular);
}
.scramble_section:focus-visible {
    background-color: var(--background-tertiary);
}
.scramble_section,
.scramble_section * {
    cursor: pointer;
}

.scramble {
    font-weight: var(--font-weight-regular) !important;
    display: inline-block !important;
}
.button {
    padding: var(--padding-regular) !important;
}
