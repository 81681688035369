.form {
    display: flex;
    align-items: center;
    gap: var(--padding-small);
    padding-top: var(--padding-small);
}
.keyvalue {
    display: flex;
    align-items: center;
    gap: var(--padding-small);
}
.date_input > input {
    padding: var(--padding-small) var(--padding-regular);
}
