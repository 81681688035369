:root {
    --font-family: "Roboto", helvetica, Arial, sans-serif;

    --font-size-regular: 16px;
    --font-size-medium: 1.125rem;
    --font-size-large: 1.625rem;
    --font-size-main-time: 120px;

    --font-weight-regular: 100;
    --font-weight-medium: 400;
    --font-weight-bold: 700;

    --padding-tiny: 0.25rem;
    --padding-small: 0.5rem;
    --padding-medium: 0.75rem;
    --padding-regular: 1rem;
    --padding-large: 1.5rem;
    --border-radius: 0.5rem;
    --sidebar-width: 18.75rem;
    --negative-sidebar-width: -18.75rem;
    --header-max-height: 10.9375rem;
    --header-min-height: 3.5rem;
    --input-width: 21.875rem;

    --modal-min-width: 26rem;
    --modal-min-height: 18.5rem;
    --modal-max-width: 75vw;
    --modal-max-height: 90vh;

    --theme-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    --item-shadow: 0.25rem 0.2rem 1.25rem 0.25rem #00000010;

    font-size: var(--font-size-regular);
}

* {
    margin: 0rem;
    padding: 0rem;
    cursor: default;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    scrollbar-width: none;
    outline: unset;
}

*::-webkit-scrollbar {
    display: none;
}

html,
body,
#root,
#app {
    width: 100%;
    height: 100%;
}

body {
    background-color: var(--background-primary);
    overflow: hidden;
}

#root,
#app {
    display: flex;
    flex-direction: column;
}

a {
    text-decoration: none;
}

ul {
    list-style: none;
}

input,
select,
button,
fieldset {
    border: unset;
    background-color: unset;
}

dialog {
    border: unset;
}
