.card {
    display: flex;
    flex-direction: column;
    width: calc(var(--modal-max-width) - (var(--modal-padding) * 2));
    height: calc(var(--modal-max-height) - (var(--modal-padding) * 2));
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.body {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: scroll;
}
@media only screen and (max-width: 768px) {
    .card {
        width: 100%;
    }
}
