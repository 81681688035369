.row {
    display: flex;
}
.item {
    flex: 1;
}
@media only screen and (max-width: 768px) {
    @media only screen and (max-width: 768px) {
        .row > *:not(:first-child) {
            border-left: var(--border);
        }
        .row > * {
            border-radius: 0rem;
        }
    }
}
