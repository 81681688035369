.modal,
.form {
    display: flex;
    flex-direction: column;
}
.modal {
    flex: 1;
    justify-content: space-between;
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.body {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: var(--padding-small);
    padding: var(--padding-small) 0rem;
}
.body > * {
    display: block;
}
