.statistic {
    padding: var(--padding-small);
    border-radius: var(--border-radius);
}
.statistic,
.statistic * {
    cursor: pointer;
}
.statistic:hover,
.statistic:focus-visible {
    background-color: var(--background-tertiary);
}
