.label {
    display: flex;
    font-family: var(--font-family);
    font-size: var(--font-size-regular);
    font-weight: var(--font-weight-regular);
    padding: var(--padding-regular);
    gap: var(--padding-small);
    width: 100%;
    border-radius: var(--border-radius);
    min-width: var(--input-width);
    color: var(--text-color);
    align-items: center;
}
.label:hover,
.label:focus-visible {
    background-color: var(--background-tertiary);
}
.label:active {
    background-color: var(--background-quaternary);
}
.checkbox {
    width: 1.2rem;
    height: 1.2rem;
}

.checkbox,
.switch,
.label,
.label * {
    cursor: pointer;
}

.checkbox:checked:hover,
.switch:checked:hover,
.checkbox:checked:focus-visible,
.switch:checked:focus-visible {
    background-color: var(--blue-secondary);
}

.checkbox:checked:active,
.switch:checked:active {
    background-color: var(--blue-tertiary);
}

.checkbox:hover,
.switch:hover,
.checkbox:focus-visible,
.switch:focus-visible {
    background-color: var(--background-tertiary);
}

.checkbox:active,
.switch:active {
    background-color: var(--background-quaternary);
}

.switch {
    --button-size: 1.6rem;
    --width: calc(var(--button-size) * 2);
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: var(--width);
    height: var(--button-size);
    border: solid 1px var(--light-grey);
    border-radius: var(--width);
}

.switch:after {
    content: "";
    position: absolute;
    left: 0%;
    width: var(--button-size);
    height: var(--button-size);
    background-color: var(--light-grey);
    border-radius: var(--button-size);
    transition: all 0.2s;
    -moz-transition: all 0.2s;
    -webkit-transition: all 0.2s;
}

.switch:checked:after {
    left: 100%;
    background-color: var(--white);
    transform: translateX(-100%);
}

.switch:checked {
    background-color: var(--blue);
    border: hidden;
}

.switch:active:after,
.label:active .switch:after {
    width: calc(var(--button-size) * 1.15);
}
