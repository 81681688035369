.row_container {
    --item-width: 7.5rem;
    display: flex;
    align-items: center;
    gap: var(--padding-small);
}
.row_input {
    min-width: unset;
    width: var(--item-width);
}
.row_input > input {
    min-width: unset;
    width: 100%;
}
.row_add_button {
    padding: var(--padding-regular);
    width: var(--item-width);
}
.item_remove_button {
    padding: 0rem var(--padding-regular);
}
.input > input::-webkit-outer-spin-button,
.input > input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.input > input {
    appearance: textfield;
    -moz-appearance: textfield;
}
