@font-face {
    font-family: "Roboto";
    font-display: swap;
    src: url(../fonts/roboto/Roboto-Thin.ttf);
    font-weight: 100;
}
@font-face {
    font-family: "Roboto";
    font-display: swap;
    src: url(../fonts/roboto/Roboto-Regular.ttf);
    font-weight: 400;
}
@font-face {
    font-family: "Roboto";
    font-display: swap;
    src: url(../fonts/roboto/Roboto-Bold.ttf);
    font-weight: 700;
}
