.form {
    padding: 0rem var(--padding-regular);
    width: fit-content;
}
.section {
    display: flex;
    flex-direction: column;
    gap: var(--padding-small);
}
.header p {
    font-weight: var(--font-weight-medium);
    padding-bottom: var(--padding-small);
}
.sidebar_list_input > select,
.sidebar_list_input > input,
.personal_bests_input > select,
.personal_bests_input > input,
.personal_bests_number_input > input,
.sidebar_list_number_input > input,
.sidebar_list_button,
.personal_bests_button {
    padding: var(--padding-small) var(--padding-regular);
}

.sidebar_list_input,
.sidebar_list_number_input,
.personal_bests_input,
.sidebar_list_input > input,
.sidebar_list_number_input > input,
.personal_bests_input > select,
.sidebar_list_input > select,
.sidebar_list_number_input > select,
.personal_bests_input > input {
    min-width: 6rem !important;
    max-width: 6rem;
}
