.toggle,
.toggle * {
    cursor: pointer;
}
.toggle {
    --dimensions: 2.5rem;
    font-size: var(--font-size-regular);
    font-weight: var(--font-weight-medium);
    display: flex;
    position: absolute;
    top: 0rem;
    left: 0rem;
    width: var(--dimensions);
    height: var(--dimensions);
    align-items: center;
    justify-content: center;
    background-color: var(--background-secondary);
    color: var(--text-color);
    transition:
        var(--theme-transition),
        background-color none;
    -moz-transition:
        var(--theme-transition),
        background-color none;
    -webkit-transition:
        var(--theme-transition),
        background-color none;
}
.toggle:hover,
.toggle:focus-visible {
    background-color: var(--background-tertiary);
}
.toggle:active {
    background-color: var(--background-quaternary);
}
.mobile_sidebar {
    position: fixed;
    right: 0rem;
    top: 0rem;
    z-index: 1;
}
