.card {
    display: flex;
    flex-direction: column;
}
.header {
    display: flex;
    flex-direction: column;
}
.header_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
