.shared {
    font-family: var(--font-family);
    font-size: var(--font-size-regular);
    font-weight: var(--font-weight-medium);
    color: var(--text-color);
    padding: var(--padding-regular) var(--padding-small);
    border-radius: var(--border-radius);
    transition:
        var(--theme-transition),
        background-color none;
    -moz-transition:
        var(--theme-transition),
        background-color none;
    -webkit-transition:
        var(--theme-transition),
        background-color none;
}
.shared,
.shared * {
    cursor: pointer;
}

.shared:disabled,
.shared:disabled * {
    cursor: default;
}
