.shared {
    flex-direction: row;
    min-width: var(--input-width);
    border-radius: var(--border-radius);
    border: solid 1px var(--background-tertiary);
    background-color: var(--background-secondary);
}

.shared:focus-visible,
.focused {
    border: solid 1px var(--grey);
}
.shared:disabled,
.shared:disabled:hover,
.shared:disabled:focus-visible,
.shared:disabled:active {
    border: unset !important;
}
.shared:disabled {
    filter: brightness(0.9);
}
.shared:hover,
.inner::placeholder,
.inner:invalid {
    color: var(--placeholder);
}
.inner {
    padding: var(--padding-regular);
    flex: 1;
}
.inner,
.shared {
    font-family: var(--font-family);
    font-size: var(--font-size-regular);
    font-weight: var(--font-weight-regular);
    color: var(--text-color);
    display: flex;
}
