.form {
    display: flex;
    flex-direction: column;
    gap: var(--padding-small);
}
.span {
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: var(--padding-small);
    align-items: center;
}
.footer {
    display: flex;
    flex-direction: column;
}
