.form {
    display: flex;
}
.ul {
    display: flex;
    flex: 1;
    flex-direction: column;
}
.item {
    display: flex;
    align-items: center;
}
.keycapture_container {
    display: flex;
    align-items: center;
    flex: 1;
}
.checkbox_label {
    flex: 0.25;
}
.value {
    display: flex;
    flex: 1;
    flex-direction: column;
    transition: none;
    -webkit-transition: none;
    -moz-transition: none;
}
.toggle_hotkey_button {
    width: 3.4rem;
}
