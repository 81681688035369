.button,
.link {
    composes: shared from "../../css/Shared.module.css";
}
.button:hover,
.button:focus-visible,
.link:hover,
.link:focus-visible {
    background-color: var(--background-tertiary);
}
.button:active,
.link:active {
    background-color: var(--background-quaternary);
}
