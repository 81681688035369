.modal {
    --modal-padding: var(--padding-large);
    display: flex;
    position: fixed;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    min-width: var(--modal-min-width);
    max-width: var(--modal-max-width);
    min-height: var(--modal-min-height);
    max-height: var(--modal-max-height);
    background-color: var(--background-secondary);
    transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -webkit-transition: opacity 0.3s ease-in-out;
    z-index: 1;
}
.inner {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: var(--modal-padding);
}

.modal > * {
    overflow: hidden;
}
.modal::backdrop {
    cursor: pointer;
    background-color: var(--modal-background);
    transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -webkit-transition: opacity 0.3s ease-in-out;
}
.modalOpen::backdrop {
    opacity: 1;
}
.modalClosed::backdrop {
    opacity: 0;
}

@media only screen and (max-width: 768px) {
    .modal {
        max-width: 100%;
        max-height: 100%;
    }
}
