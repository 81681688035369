.accordion {
    padding: var(--padding-small) var(--padding-small) 0rem var(--padding-small);
    overflow: hidden;
}

.buttons {
    display: flex;
}
.button {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}
