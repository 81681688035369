.keycapture {
    width: 100%;
    min-width: calc(var(--input-width) / 2);
    padding: var(--padding-regular);
    border: solid 1px transparent;
    border-radius: var(--border-radius);
}
.keycapture:hover,
.keycapture:focus-visible {
    color: var(--text-color);
    border: solid 1px var(--grey);
}
.focused {
    color: var(--placeholder) !important;
    border: solid 1px var(--text-color) !important;
}

.keycapture,
.keycapture * {
    cursor: pointer;
}
.inactive {
    filter: brightness(0.85);
    -moz-filter: brightness(0.85);
    -webkit-filter: brightness(0.85);
}
