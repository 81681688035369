.footer {
    display: flex;
}
.footer > * {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}
@media only screen and (max-width: 768px) {
    .footer > *:not(:first-child) {
        border-left: var(--border);
    }
    .footer > * {
        border-radius: 0rem;
    }
}
