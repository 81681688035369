.home {
    display: flex;
    width: 100%;
    height: 100%;
}
.subsection {
    display: flex;
    flex-direction: column;
    flex: 1;
}
