.diagram {
    position: absolute;
    right: 0;
    bottom: 0;
}
.diagram,
.diagram * {
    cursor: pointer;
}
.diagram:focus-visible {
    border: solid 1px var(--grey);
}

@media only screen and (max-width: 768px) {
    .diagram {
        left: unset;
        right: unset;
    }
}
