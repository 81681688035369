.sidebar {
    display: flex;
    flex-direction: column;
    background-color: var(--background-secondary);
    width: var(--sidebar-width);
    height: 100%;
    padding: 0rem var(--padding-small) var(--padding-small) var(--padding-small);
    gap: var(--padding-small);
    transition: var(--theme-transition);
    -moz-transition: var(--theme-transition);
    -webkit-transition: var(--theme-transition);
    z-index: 1;
}
.logo_wrapper {
    padding: var(--padding-regular);
}
.logo {
    display: flex;
    flex-direction: column;
    height: var(--header-min-height);
    align-items: center;
    justify-content: center;
    letter-spacing: 0.025em;
}

@media only screen and (max-width: 768px) {
    .sidebar {
        position: fixed;
        width: 100%;
    }
}
