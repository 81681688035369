.form {
    display: flex;
    flex-direction: column;
    gap: var(--padding-small);
}
.header p {
    font-weight: var(--font-weight-medium);
}
.ul {
    display: flex;
    flex: 1;
    flex-direction: column;
}
.delete_modal_ul {
    display: flex;
    flex-direction: column;
    gap: var(--padding-small);
    padding: 0rem var(--padding-small);
}
.label {
    padding: var(--padding-small) var(--padding-regular);
}
.item {
    display: flex;
    align-items: center;
}

.modal_item {
    display: block;
    font-weight: var(--font-weight-medium);
}
.modal_detail {
    display: block;
    white-space: pre-line;
    padding: 0rem var(--padding-small);
}
.footer {
    display: flex;
    flex-direction: column;
}
