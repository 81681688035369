.body {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.projection_column {
    display: flex;
    flex-direction: column;
}
.face {
    display: flex;
    flex-direction: column;
}
.face,
.face * {
    cursor: pointer;
}
.face > * {
    position: relative;
}
.face_row {
    display: flex;
    flex-direction: row;
}
.square {
    display: flex;
    width: 2rem;
    height: 2rem;
    border: solid 1px var(--black);
}
.stickerless {
    border: hidden;
}
.face:hover .delete_color {
    display: block;
}
.delete_color {
    --size: 2rem;
    display: none;
    color: var(--grey);
    position: absolute;
    top: 0rem;
    right: 0rem;
    padding: 0rem;
    width: var(--size);
    height: var(--size);
    align-items: center;
}
.delete_color:hover,
.delete_color:focus-visible,
.delete_color:active {
    color: var(--text-color);
}
