.body {
    display: flex;
    flex-direction: column;
    gap: var(--padding-large);
}

.body > :not(:last-child) {
    border-bottom: var(--border);
    padding-bottom: var(--padding-large);
}
