.form {
    display: flex;
    flex-direction: column;
}
.row {
    display: flex;
}

.button {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
}
