.solve {
    border-top: var(--border-transparent);
    border-bottom: var(--border-transparent);
    padding: var(--padding-tiny) 0rem;
    border-radius: var(--border-radius);
}
.solve,
.solve * {
    cursor: pointer;
}
.time {
    display: block;
    font-size: var(--font-size-medium);
    padding: 0rem var(--padding-small);
}
.focused {
    border-top: var(--border);
    border-bottom: var(--border);
    background-color: var(--background-tertiary);
    border-radius: 0rem;
}

.solve:hover,
.solve:focus-visible {
    background-color: var(--background-tertiary);
}
