.no_appearance,
.no_appearance > input {
    height: 0px;
}

.children_wrapper {
    --border-width: 2px;
    border: solid var(--border-width) transparent;
}
.children_wrapper,
.children_wrapper * {
    cursor: pointer;
}
.children_wrapper:hover,
.children_wrapper:focus-visible {
    border: solid var(--border-width) var(--grey);
}
.children_wrapper:active {
    border: solid var(--border-width) var(--text-color);
}
.disabled,
.disabled:hover,
.disabled:focus-visible,
.disabled:active {
    border: solid var(--border-width) transparent;
}

.disabled,
.disabled * {
    cursor: default;
}
