.submit {
    composes: shared from "../../css/Shared.module.css";
    align-items: center;
    justify-content: center;
    background-color: var(--blue);
    color: var(--white);
}
.submit:hover {
    background-color: var(--blue-secondary);
}
.submit:active {
    background-color: var(--blue-tertiary);
}
.submit:disabled {
    background-color: var(--background-tertiary);
    filter: brightness(0.85);
    -moz-filter: brightness(0.85);
    -webkit-filter: brightness(0.85);
}
