.list_item {
    width: 100%;
}
.session {
    width: 100%;
    font-weight: var(--font-weight-regular);
    text-align: left;
    border-top: var(--border-transparent);
    border-bottom: var(--border-transparent);
}
.current {
    border-top: var(--border);
    border-bottom: var(--border);
    border-radius: 0rem;
}
