.table {
    --table-border: solid 1px var(--grey);
    --cell-padding: 0.75rem 1rem;
    display: block;
    width: 100%;
    overflow: scroll;
    border-collapse: collapse;
}
.fixed {
    table-layout: fixed;
}

.head_cell {
    position: sticky;
    top: 0px;
    text-align: left;
    white-space: nowrap;
    border-bottom: var(--table-border);
    background-color: var(--background-secondary);
}
.head_cell_content {
    font-weight: var(--font-weight-medium);
}
.head_cell:not(:first-child),
.cell:not(:first-child) {
    border-left: var(--table-border);
}
.head_cell,
.cell {
    padding: var(--padding-medium) var(--padding-regular);
}
.table > tbody > tr:not(:last-child) > td {
    border-bottom: var(--table-border);
}
.centered {
    text-align: center;
}
.nowrap {
    white-space: nowrap;
}
.clickable,
.clickable * {
    cursor: pointer;
}
.input,
.input * {
    cursor: text;
}
.input,
.input > input {
    display: inline-block;
    width: 8rem;
}
.input > input {
    font-family: var(--font-family);
    font-size: var(--font-size-regular);
    font-weight: var(--font-weight-regular);
    color: var(--text-color);
    padding: 0rem;
}
.input > input::placeholder {
    color: var(--placeholder);
}
