.wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--padding-regular);
}
.wrapper > .header {
    margin-bottom: unset;
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--padding-regular);
}
