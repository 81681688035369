.light,
.dark {
    --white: #fafafa;
    --black: #202020;
    --table-border: #8f8f9f;
    --top: var(--white);
    --down: var(--yellow);
    --left: var(--orange);
    --right: var(--red);
    --front: var(--green);
    --back: var(--blue);
    --minx-down-right: var(--light-yellow);
    --minx-down-left: var(--light-blue);
    --minx-left: var(--purple);
    --minx-back-right: var(--pink);
    --minx-down: var(--light-grey);
    --minx-back: var(--light-green);
}

.light,
.light ::backdrop {
    --text-color: var(--black);
    --background-primary: #dadae6;
    --background-secondary: #e4e4f5;
    --background-tertiary: #d3d3e8;
    --background-quaternary: #cfcfe3;
    --blue: #017eff;
    --blue-secondary: #0c81fa;
    --blue-tertiary: #006edb;
    --light-blue: #37bdfa;
    --red: #dd0000;
    --green: #339601;
    --light-green: #7be104;
    --orange: #f57d09;
    --yellow: #fefe65;
    --light-yellow: #fbdf62;
    --purple: #9200e9;
    --pink: #fe48ad;
    --light-grey: #cccccc;
    --grey: #a0a0a2;
    --shadow-color: #8f8f9f3f;
    --modal-background: #dadadf88;
    --placeholder: #59667a;
    --border: solid 1px var(--grey);
    --border-transparent: solid 1px transparent;
    --border-shadow-left: inset 1px 0px 0px var(--grey);
    --border-shadow-vertical: inset 0px 1px 0px var(--grey), inset 0px -1px 0px var(--grey);
}

.dark,
.dark ::backdrop {
    --text-color: var(--white);
    --background-primary: #3a3a3f;
    --background-secondary: #4a4a4f;
    --background-tertiary: #63636b;
    --background-quaternary: #585866;
    --blue: #017eff;
    --blue-secondary: #1186fa;
    --blue-tertiary: #0062c7;
    --light-blue: #37bdfa;
    --red: #dd0000;
    --green: #02e100;
    --light-green: #7be104;
    --orange: #ff8b1a;
    --yellow: #f6ff00;
    --light-yellow: #fbdf62;
    --purple: #9200e9;
    --pink: #fe48ad;
    --light-grey: #cccccc;
    --grey: #808092;
    --shadow-color: #1a1a1f2f;
    --modal-background: #1a1a1f88;
    --placeholder: #9dadc9;
    --border: solid 1px var(--grey);
    --border-transparent: solid 1px transparent;
    --border-shadow-left: inset 1px 0px 0px var(--grey);
    --border-shadow-vertical: inset 0px 1px 0px var(--grey), inset 0px -1px 0px var(--grey);
}
.body_transition {
    transition: var(--theme-transition);
    -webkit-transition: var(--theme-transition);
    -moz-transition: var(--theme-transition);
}
