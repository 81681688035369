.container {
    position: relative;
    overflow: hidden;
    align-items: center;
}
.main,
.container {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.main {
    --box-shadow: inset 0rem 0rem 1.25rem 0.25rem var(--shadow-color);
    -moz-box-shadow: var(--box-shadow);
    -webkit-box-shadow: var(--box-shadow);
    box-shadow: var(--box-shadow);
    width: 100%;
    align-items: center;
    justify-content: center;
}
.main_time {
    font-weight: var(--font-weight-regular) !important;
    font-size: var(--font-size-main-time) !important;
}
